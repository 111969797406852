.fadeTranslate-enter {
  opacity: 0;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.fadeTranslate-exit {
  display: none;
}
//
// .fadeTranslate-exit.fadeTranslate-exit-active {
//   opacity: 0;
//   transform: translate(0, 3vh);
//   transition: opacity 1000ms ease-in, transform 1000ms ease-in-out;
// }
//
// .fix-container {
//   position: fixed;
// }
